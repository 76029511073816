.page-title h3 {
  margin: 0;
  text-align: center;
  color: #ff5140;
}
@media (min-width: 959px) {
  .toastClass {
    left: calc(50vw - 150px);
  }
}
md-toast.md-success-toast-theme .md-toast-content {
  background-color: green;
}
md-toast.md-error-toast-theme .md-toast-content {
  background-color: red;
}
body {
  font-family: Varela Round, arial, sans-serif;
}
.container {
  height: auto;
}
.container .no-js {
  text-align: center;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    /* IE10+ Overrides */
    height: inherit;
  }
}
@media (min-width: 960px) {
  .container {
    margin: 0 auto;
    width: 80%;
  }
}
.container .header-block__title {
  font-size: 26px;
  line-height: 1.2em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}
@media (max-width: 600px) {
  .container .header-block__title {
    text-align: left;
    padding: 10px;
  }
}
.container .header-block__title a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
.container .header-block__title a:hover {
  color: #ff5140;
}
.container .header-block__nav-bar:before,
.container .header-block__nav-bar:after {
  background-color: #ddd;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.container .header-block__nav-bar .md-nav-bar {
  border: 0;
}
.container .header-block__nav-bar .md-nav-bar li button {
  line-height: 1.6;
  font-size: 18px;
}
.container .header-block__nav-bar .md-nav-bar li button:focus {
  background: none;
}
.container .header-block__nav-bar .md-nav-bar li button.md-unselected {
  color: #999;
}
.container .header-block__nav-bar .md-nav-bar li button.md-unselected:hover {
  color: #222;
  border-bottom: 2px solid #ddd;
}
.container .header-block__brand-icon {
  border-radius: 4px;
}
.container .header-block__side-nav-item {
  cursor: pointer;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container section {
    /* IE10+ Overrides */
    overflow: auto;
  }
}
.container section .landing__profile-picture img {
  width: 100%;
}
.container section .landing__profile-content {
  color: #5b5b5b;
}
.container section .landing__profile-content a {
  color: inherit;
  text-decoration: none;
}
.container section .landing__profile-content a:hover {
  color: #ff4081;
  text-decoration: none;
}
.container section .portfolio__page-title h3 {
  margin: 0;
  text-align: center;
  color: #ff5140;
}
.container section .portfolio__project-logo {
  text-align: center;
  margin-bottom: 10px;
}
.container section .portfolio__portfolio-content md-card-content md-card img {
  height: 100px;
}
.container section .portfolio h4 {
  margin-bottom: 0px;
}
.container section .portfolio md-card {
  max-height: 500px;
  transition: max-height 0.5s ease-out;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12);
}
.container section .portfolio .animate {
  max-height: 1000px;
  transition: max-height 0.75s ease-in;
}
.container section .portfolio .md-card-image {
  height: 120px;
}
.container section .portfolio .md-card-image[alt='GRID'] {
  background: lightblue;
}
.container section .portfolio .md-card-image[alt='JCLD'] {
  background: antiquewhite;
}
.container section .portfolio .md-card-image[alt='CALENDAR'] {
  background: lightgray;
}
.container section .portfolio .md-card-image[alt='TDC'] {
  background: lightsteelblue;
}
.container section .portfolio .md-card-image[alt='GEM'] {
  background: antiquewhite;
}
.container section .portfolio .md-card-image[alt='ODP'] {
  background: lightblue;
}
.container section .portfolio .md-card-image[alt='PORTAL'] {
  background: antiquewhite;
}
.container section .portfolio .md-headline {
  color: #ff5140;
  font-size: 20px;
}
.container section .portfolio__project-summary {
  color: #5b5b5b;
  font-size: 16px;
  line-height: 24px;
}
.container section .portfolio__project-summary ul {
  list-style-type: circle;
}
.container section .work {
  color: #5b5b5b;
}
.container section .work__page-title h3 {
  margin: 0;
  text-align: center;
  color: #ff5140;
}
.container section .work timeline timeline-badge {
  z-index: 20;
}
.container section .work__project-title img[alt="technumen"] {
  height: 75px;
}
.container section .work__project-content {
  font-size: 16px;
  line-height: 24px;
}
.container section .work__pill {
  background-color: #eee;
  border-radius: 15px;
  padding: 8px;
  margin: 5px;
  display: inline-block;
}
.container section .work__pill span {
  vertical-align: middle;
}
.container section .blog__page-title h3 {
  margin: 0;
  text-align: center;
  color: #ff5140;
}
.container section .blog md-card {
  margin-bottom: 20px;
  min-height: 250px;
  box-shadow: none;
  background: none;
  color: #5b5b5b;
}
.container section .blog md-icon {
  margin: 5px 15px 15px 0;
}
.container section .blog a {
  color: #482727;
  text-decoration: none;
}
.container section .blog a:hover {
  color: #ff5140;
}
.container section .blog__error-text {
  color: red;
}
.container section .blog__medium-icon {
  margin: 5px 5px 10px 0;
  width: 100px;
}
.container section .blog__article-tags {
  margin: 5px;
  padding: 5px;
  display: inline-block;
  border-radius: 4px;
  background-color: #ddd;
}
.container section .blog__article-image {
  margin-bottom: 10px;
}
.container section .blog__article-image img {
  border-radius: 4px;
}
.container section .contact__page-title h3 {
  margin: 0;
  text-align: center;
  color: #ff5140;
}
.container section .contact iframe {
  height: 250px;
}
.container section .contact .md-headline {
  color: #5b5b5b;
}
.container section .contact md-input-container {
  margin: 10px 0;
}
.container section .contact .md-button {
  width: 50px;
  align-self: flex-end;
}
.container section .contact__container {
  background: #474559;
  color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.container section .contact__links {
  margin: 5px;
}
.container section .contact a {
  text-decoration: none;
  text-transform: none;
  margin-bottom: 5px;
  cursor: pointer;
  color: #fff;
}
.container section .contact a:hover {
  color: #ff5140;
}
.container .footer-block__list {
  margin: 10px;
}
.container .footer-block__social-icons a {
  color: inherit;
  text-decoration: none;
}
.container .footer-block__social-icons a:hover .fa-skype {
  color: #00AFF0;
}
.container .footer-block__social-icons a:hover .fa-twitter {
  color: #00aced;
}
.container .footer-block__social-icons a:hover .fa-github {
  color: #ff5140;
}
.container .footer-block__social-icons a:hover .fa-linkedin {
  color: #0077B5;
}
