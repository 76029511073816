//Variables
@primaryColor : rgb(255, 64, 129);
@primaryColor2: #ff5140;
@textColor:#5b5b5b;
@viewPortMedium : 960px;
@viewPortSmall: 600px;
//mixins
.page-title{
  h3{
    margin:0;
    text-align: center;
    color:@primaryColor2;
  }
}
@media (min-width: 959px){
  .toastClass{
    left: calc(50vw - 150px);
  }
}
md-toast.md-success-toast-theme {
  .md-toast-content {
      background-color: green;
  }
}
md-toast.md-error-toast-theme {
  .md-toast-content {
      background-color: red;
  }
}

body{
  font-family: Varela Round, arial, sans-serif;
}
.container{
  height:auto;
  .no-js{
    text-align: center;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ Overrides */
    height:inherit;
  }
  @media (min-width: @viewPortMedium){
   & {
    margin:0 auto;
    width:80%;
   }
  }
  .header-block{
    &__title{
      font-size: 26px;
      line-height: 1.2em;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 3px;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      @media (max-width: @viewPortSmall){
        &{
          text-align: left;
          padding:10px;
        }
      }
      a{
        text-decoration: none;
        cursor:pointer;
        color:inherit;
        &:hover{
          color:@primaryColor2;
        }
      }
    }
    &__nav-bar{
      &:before,
      &:after {
        background-color: #ddd;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
      .md-nav-bar{
        border:0;
        li{
          button{
            line-height: 1.6;
            font-size:18px;
            &:focus{
              background: none;
            }
            &.md-unselected{
              color:#999;
              &:hover{
                color:#222;
                border-bottom:2px solid #ddd;
              }
            } 
          }
        }
      }
    }
    &__brand-icon{
      border-radius:4px;
    }
    &__side-nav-item{
      cursor: pointer;
    }
  }
  section{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ Overrides */
      overflow:auto;
    }
    .landing{
      &__profile-picture{
        img{
          width:100%;
        }
      }
      &__profile-content{
        color:@textColor;
        a{
          color:inherit;
          text-decoration: none;
          &:hover{
            color:@primaryColor;
            text-decoration: none;
          }
        }
      }
    }
    .portfolio{
      &__page-title{
        .page-title;
      }
      &__project-logo{
        text-align:center;
        margin-bottom:10px;
      }
      &__portfolio-content md-card-content md-card img{
        height:100px;
      }
      h4{
        margin-bottom:0px;
      }
      md-card{
        max-height:500px;
        transition: max-height 0.5s ease-out;
        border-radius: 5px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 
        0px 1px 1px -1px rgba(0, 0, 0, 0.12)
      }
      .animate{
        max-height: 1000px;
        transition:max-height 0.75s ease-in;
      }
      .md-card-image{
        height:120px;
        &[alt = 'GRID']{
          background: lightblue;
        }
        &[alt = 'JCLD']{
          background: antiquewhite;
        }
        &[alt = 'CALENDAR']{
          background: lightgray;
        }
        &[alt = 'TDC']{
          background: lightsteelblue;
        }
        &[alt = 'GEM']{
          background: antiquewhite;
        }
        &[alt = 'ODP']{
          background: lightblue;
        }
        &[alt = 'PORTAL']{
          background: antiquewhite;
        }
      }
      .md-headline{
        color:@primaryColor2;
        font-size: 20px;
      }
      &__project-summary{
        color:@textColor;
        font-size: 16px;
        line-height: 24px;
        ul{
          list-style-type: circle;
        }
      }
    }
    .work{
      color:@textColor;
      &__page-title{
        .page-title;
      }
      timeline{
        timeline-badge{
          z-index:20;
        }
      }
      &__project-title{
        img[alt="technumen"]{
          height: 75px;
        }
      }
      &__project-content{
        font-size: 16px;
        line-height: 24px;
      }
      &__pill{
        background-color:#eee;
        border-radius:15px;
        padding:8px;
        margin:5px;
        display:inline-block;
        span{
          vertical-align: middle;
        }
      }
    }
    .blog{
      &__page-title{
        .page-title;
      }
      md-card{
        margin-bottom:20px;
        min-height: 250px;
        box-shadow: none;
        background:none;
        color:@textColor;
      }
      md-icon{
        margin: 5px 15px 15px 0;
      }
      a{
        color:#482727;
        text-decoration: none;
        &:hover{
          color:@primaryColor2;
        }
      }
      &__error-text{
        color:red;
      }
      &__medium-icon{
        margin: 5px 5px 10px 0;
        width:100px;
      }
      &__article-tags{
        margin:5px;
        padding:5px;
        display: inline-block;
        border-radius:4px;
        background-color:#ddd;
      }
      &__article-image{
        margin-bottom:10px;
        img{
          border-radius: 4px;
        }
      }
    }
    .contact{
      &__page-title{
        .page-title;
      }
      iframe{
        height:250px;
      }
      .md-headline{
        color:#5b5b5b;
      }
      md-input-container{
        margin:10px 0;
      }
      .md-button{
        width:50px;
        align-self: flex-end;
      }
      &__container{
        background:#474559;
        color:#fff;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &__links{
        margin:5px;
      }
      a{
        text-decoration: none;
        text-transform: none;
        margin-bottom:5px;
        cursor: pointer;
        color:#fff;
        &:hover{
          color:@primaryColor2;
        }
      }
    }
  }
  .footer-block{
    &__list{
      margin:10px;
    }
    &__social-icons{
      a{
        color:inherit;
        text-decoration: none;
        &:hover{
          .fa-skype{
            color: #00AFF0;
          }
          .fa-twitter{
            color: #00aced;
          }
          .fa-github{
            color: #ff5140;
          }
          .fa-linkedin{
            color: #0077B5;
          }
        }
      }
    }
  }
}